import apiClient from "./api";

export const login = async (data: any) => {
  const response: any = await apiClient.post(`user/login`, data);
  return response;
};

export const logout = async (username: string) => {
  const response: any = await apiClient.post(`user/logout`, { username });
  return response;
};

export const register = async (data: any) => {
  const response: any = await apiClient.post(`user/logout`, data);
  return response;
};

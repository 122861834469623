import { useState } from "react";
import { ContentTopPage } from "types/ContentTopPage";
import PopupEdit from "./component/PopupEdit";

const BodyTable = (props: {
  contentTopPage: ContentTopPage;
  setEditContentTopPage: any;
}) => {
  const { contentTopPage, setEditContentTopPage } = props;
  return (
    <>
      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
        <td className="px-6 py-4">
          <p className="">{contentTopPage.title}</p>
        </td>
        <td className="px-6 py-4">{contentTopPage.content}</td>
        <td className="px-6 py-4 w-80 h-80">
          <img src={contentTopPage.imgUrl} alt="" className="w-full h-full" />
        </td>
        <td className="px-6 py-4">
          <span
            onClick={() => setEditContentTopPage(contentTopPage)}
            className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline"
          >
            Chỉnh sửa
          </span>
        </td>
      </tr>
    </>
  );
};

export default function ContentTopPagePage(props: {
  contentTopPage: ContentTopPage;
  resetData: () => void;
}) {
  const { contentTopPage, resetData } = props;
  const [isEditContentTopPage, setIsEditContentTopPage] =
    useState<boolean>(false);

  const handleCloseEditContentTopPage = () => {
    setIsEditContentTopPage(false);
  };
  const handleResetData = () => {
    resetData();
    handleCloseEditContentTopPage();
  };

  const handleShowEditContentTopPage = () => {
    setIsEditContentTopPage(true);
  };
  return (
    <>
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14 min-h-[calc(100vh-88px)]">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Tiêu Đề
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Mội Dung
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Hình Ảnh
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Chỉnh sửa
                  </th>
                </tr>
              </thead>
              <tbody>
                <BodyTable
                  contentTopPage={contentTopPage}
                  setEditContentTopPage={handleShowEditContentTopPage}
                />
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isEditContentTopPage && contentTopPage && (
        <PopupEdit
          type="CONTENT_TOP_PAGE"
          contentNumber={contentTopPage}
          isShowEditContentNumber={isEditContentTopPage}
          handleCloseEditContentNumber={handleCloseEditContentTopPage}
          handleResetData={handleResetData}
        />
      )}
    </>
  );
}

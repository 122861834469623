import { useAppDispatch, useAppSelector } from "app/hooks";
import { logout } from "app/services/admin";
import { getContent, getEnergyNumber } from "app/services/content";
import { getUsers } from "app/services/user";
import {
  getUserData,
  removeManyUserData,
  removeUserData,
} from "app/services/userData";
import {
  selectCurrentUser,
  setAccessToken,
  setCurrentUser,
  setIsAuthenticated,
} from "features/auth/authSlice";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ContentType, EnergyNumber } from "types/ContentNumber";
import ContentNumberPage from "./components/Content/ContentNumberPage";
import ContentTopPagePage from "./components/Content/ContentTopPagePage";
import UserData from "./components/UserData";
import UserList from "./components/UserList";
import EnergyNumberPage from "./components/Content/EnergyNumberPage";

const headers = [
  { label: "Số Thứ Tự", key: "index" },
  { label: "Họ Và Tên Đệm", key: "firstName" },
  { label: "Tên", key: "lastName" },
  { label: "Giới Tính", key: "gender" },
  { label: "Sinh Nhật", key: "birthday" },
  { label: "Số điện thoại", key: "tel" },
  { label: "Email", key: "email" },
  { label: "Tên Phụ", key: "authorName" },
];

const tabsAdmin = {
  USER_DATA: "USER_DATA",
  ACCOUNT: "ACCOUNT",
  IMAGES: "IMAGES",
  CONTENT_NUMBER: "CONTENT_NUMBER",
  CLASSROOM_LINKS: "CLASS_ROOM_LINKS",
  CONTENT_TOP_PAGE: "CONTENT_TOP_PAGE",
  ENERGY_NUMBER: "ENERGY_NUMBER",
};

export default function AdminPage() {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectCurrentUser);

  const [users, setUsers] = useState<any>([]);
  const [content, setContent] = useState<any>([]);
  const [energyNumbers, setEnergyNumbers] = useState<EnergyNumber[]>([]);

  const [admin, setAdmin] = useState<boolean>(false);

  const [userDatas, setUserDatas] = useState<any>([]);
  const [tabs, setTabs] = useState<string>(tabsAdmin.USER_DATA);

  const [typeNumber, setTypeNumber] = useState<ContentType | null>(null);

  const [isLoading, setLoading] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openContent, setOpenContent] = useState<boolean>(false);
  const navigate = useNavigate();

  const [dataChecked, setDataChecked] = useState<any>([]);
  const refCSV = useRef<any>(null);

  const handleGetUserDate = async () => {
    try {
      const data = await getUserData();
      if (data && data?.data) {
        setUserDatas(data.data);
        setLoading(false);
        setDataChecked([]);
      }
    } catch (error) {
      toast.error("Có lỗi xãy ra!");
    }
  };
  useEffect(() => {
    handleGetUserDate();
    // eslint-disable-next-line
  }, []);

  const handleGetUsers = async () => {
    try {
      const data = await getUsers();
      if (data && data?.data) setUsers(data.data);
    } catch (error) {
      toast.error("Có lỗi xãy ra!");
    }
  };

  const handleGetContent = async () => {
    try {
      const data = await getContent();
      if (data && data?.data) setContent(data.data);
    } catch (error) {
      toast.error("Có lỗi xãy ra!");
    }
  };

  const handleGetEnergy = async () => {
    try {
      const data = await getEnergyNumber();
      if (data && data?.data && data?.data?.energyNumber)
        setEnergyNumbers(data.data.energyNumber);
    } catch (error) {
      toast.error("Có lỗi xãy ra!");
    }
  };

  useEffect(() => {
    if (currentUser && currentUser?.role === "ADMIN") {
      setAdmin(true);
      handleGetUsers();
      handleGetContent();
      handleGetEnergy();
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const handleLogout = () => {
    logout(currentUser?.username).finally(() => {
      dispatch(setAccessToken(null));
      dispatch(setCurrentUser(null));
      dispatch(setIsAuthenticated(false));
      navigate("/login");
    });
  };

  const handleDeleteManyData = () => {
    if (dataChecked.length === 0)
      return toast.error("Không có data được chọn!");
    const dataRemoveMany = [];
    for (let i = 0; i < dataChecked.length; i++) {
      dataRemoveMany.push(dataChecked[i]._id);
    }
    removeManyUserData(dataRemoveMany)
      .then(() => {
        setLoading(true);
        toast.success("Xóa thành công");
        handleGetUserDate();
      })
      .catch(() => toast.error("Đã xảy ra lỗi"));
  };

  const handleDeleteData = (id: string) => {
    removeUserData({ _id: id })
      .then(() => {
        toast.success("Xóa thành công");
        handleGetUserDate();
      })
      .catch(() => toast.error("Đã xảy ra lỗi"));
  };

  const handleDownloadData = () => {
    if (dataChecked.length === 0)
      return toast.error("Không có data được chọn!");
    if (refCSV.current) {
      refCSV?.current?.link.click();
    }
  };

  const handleNumberContent = (type: ContentType) => {
    setTypeNumber(null);
    setTabs(tabsAdmin.CONTENT_NUMBER);
    setTypeNumber(type);
  };

  return (
    <div className="relative">
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={() => setOpenMenu(!openMenu)}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <a href="/#" className="flex ml-2 md:mr-2USER_DATA4">
                <img src="logo.png" className="h-8 mr-3" alt="FlowBite Logo" />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  Tính Cách
                </span>
              </a>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700 ${
          openMenu ? "translate-x-0" : ""
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
          <ul className="space-y-2 font-medium">
            <li>
              <span
                className={`flex items-center p-2 cursor-pointer text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                  tabs === tabsAdmin.USER_DATA ? "bg-gray-100" : ""
                }`}
                onClick={() => setTabs(tabsAdmin.USER_DATA)}
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Data người dùng
                </span>
              </span>
            </li>
            {currentUser.role === "ADMIN" && (
              <>
                <li>
                  <span
                    className={`flex items-center p-2 cursor-pointer text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 ${
                      tabs === tabsAdmin.ACCOUNT ? "bg-gray-100" : ""
                    }`}
                    onClick={() => setTabs(tabsAdmin.ACCOUNT)}
                  >
                    <svg
                      aria-hidden="true"
                      className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                      ></path>
                    </svg>
                    <span className="flex-1 ml-3 whitespace-nowrap">
                      Tài Khoản
                    </span>
                  </span>
                </li>
                <li>
                  <button
                    type="button"
                    className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                    onClick={() => setOpenContent(!openContent)}
                  >
                    <svg
                      className="w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 21"
                    >
                      <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                      <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                    </svg>
                    <span className="flex-1 ml-3 text-left whitespace-nowrap">
                      Quản lí nội dung
                    </span>
                    <svg
                      className={`w-3 h-3 transition duration-500 ${
                        openContent ? "rotate-180 " : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                  <ul
                    id="dropdown-example"
                    className={`${
                      openContent ? "" : "hidden"
                    } py-2 space-y-2  transition duration-500`}
                  >
                    <li>
                      <span
                        onClick={() => handleNumberContent("contentNumber")}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_NUMBER &&
                          typeNumber === "contentNumber"
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Con Số Tên, Tên Tác Ý
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => handleNumberContent("contentInner")}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_NUMBER &&
                          typeNumber === "contentInner"
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Con Số Nội Tâm
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => handleNumberContent("contentInteract")}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_NUMBER &&
                          typeNumber === "contentInteract"
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Con Số Tương Tác
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => handleNumberContent("contentMission")}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_NUMBER &&
                          typeNumber === "contentMission"
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Con Số Sứ Mệnh
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => handleNumberContent("contentMature")}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_NUMBER &&
                          typeNumber === "contentMature"
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Con Số Trưởng Thành
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => handleNumberContent("contentLife")}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_NUMBER &&
                          typeNumber === "contentLife"
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Con Số Đường Đời
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setTabs(tabsAdmin.ENERGY_NUMBER)}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.ENERGY_NUMBER ? "bg-gray-100" : ""
                        }`}
                      >
                        Năng lượng Con Số
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setTabs(tabsAdmin.CONTENT_TOP_PAGE)}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CONTENT_TOP_PAGE
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Nội dung trang chủ
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => setTabs(tabsAdmin.CLASSROOM_LINKS)}
                        className={`cursor-pointer flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700 ${
                          tabs === tabsAdmin.CLASSROOM_LINKS
                            ? "bg-gray-100"
                            : ""
                        }`}
                      >
                        Link lớp học
                      </span>
                    </li>
                  </ul>
                </li>
              </>
            )}

            <li>
              <span
                onClick={handleLogout}
                className="flex items-center p-2 cursor-pointer text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                  ></path>
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Đăng Xuất</span>
              </span>
            </li>
          </ul>
        </div>
      </aside>

      {tabs === tabsAdmin.USER_DATA && (
        <UserData
          handleDeleteData={handleDeleteData}
          handleDownloadData={handleDownloadData}
          refCSV={refCSV}
          dataChecked={dataChecked}
          headers={headers}
          handleDeleteManyData={handleDeleteManyData}
          setDataChecked={setDataChecked}
          userDatas={userDatas}
          isLoading={isLoading}
        />
      )}
      {tabs === tabsAdmin.ACCOUNT && admin && (
        <UserList
          userDatas={users}
          isLoading={isLoading}
          handleGetUsers={handleGetUsers}
        />
      )}
      {tabs === tabsAdmin.CONTENT_NUMBER &&
        admin &&
        typeNumber &&
        content[typeNumber] && (
          <ContentNumberPage
            contentNumbers={content[typeNumber]}
            resetData={handleGetContent}
            typeNumber={typeNumber}
          />
        )}

      {tabs === tabsAdmin.CONTENT_TOP_PAGE &&
        admin &&
        content?.contentTopPage && (
          <ContentTopPagePage
            contentTopPage={content?.contentTopPage}
            resetData={handleGetContent}
          />
        )}

      {tabs === tabsAdmin.ENERGY_NUMBER && admin && energyNumbers && (
        <EnergyNumberPage
          energyNumbers={energyNumbers}
          resetData={handleGetEnergy}
        />
      )}
    </div>
  );
}

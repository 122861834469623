export default function TextAreaRegister(props: {
  register: any;
  errMsg: any;
  isAutoFill: boolean;
  label: string;
  name: string;
  disabled?: boolean;
  className?: string;
}) {
  const { register, errMsg, isAutoFill, label, name, disabled, className } =
    props;
  return (
    <div className={`${className}`}>
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
        {label}
      </label>
      <textarea
        disabled={disabled}
        rows={4}
        autoComplete={`${isAutoFill ? "" : "one-time-code"}`}
        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 resize-none"
        {...register(name)}
      />
      {errMsg && (
        <p className="text-red-400 text-sm font-light whitespace-pre-line">
          {errMsg}
        </p>
      )}
    </div>
  );
}

TextAreaRegister.defaultValue = {
  disabled: false,
  className: "",
};

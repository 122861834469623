import { ROUTES } from "app/configs/routers";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { getUser } from "app/services/user";
import {
  selectAccessToken,
  selectIsAuthenticated,
  setAccessToken,
  setCurrentUser,
  setIsAuthenticated,
} from "features/auth/authSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function PrivateRoute(props: any) {
  const { children } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const accessToken = useAppSelector(selectAccessToken);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  async function init() {
    if (!accessToken) {
      return navigate("/login");
    }
    getUser()
      .then((response: any) => {
        dispatch(setCurrentUser(response.data));
        dispatch(setIsAuthenticated(true));
      })
      .catch((error: any) => {
        /* eslint-disable-next-line no-console */
        console.error(error);
        dispatch(setCurrentUser(null));
        dispatch(setAccessToken(null));
        dispatch(setIsAuthenticated(false));
        return navigate(ROUTES.LOGIN);
      });
  }

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !isAuthenticated ? (
    <section className="flex items-center justify-center h-screen">
      Loading
    </section>
  ) : (
    children
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { ContentNumber, EnergyNumber } from "types/ContentNumber";
import {
  classRoomLinksValidate,
  contentNumberValidate,
  contentTopPageValidate,
  energyNumberValidate,
} from "./configs/validation";
import TextAreaRegister from "app/components/TextAreaRegister";
import { useEffect } from "react";
import { editContent, editEnergyNumber } from "app/services/content";
import { toast } from "react-toastify";
import { ContentTopPage } from "types/ContentTopPage";
import { ClassRoomLinks } from "types/ClassRoomLinks";
import InputRegister from "app/components/InputRegister";

const defaultValuesContentNumber: ContentNumber = {
  number: "",
  badoc: "",
  bagoc: "",
  mota: "",
  canbang: "",
};

const defaultValuesContentTopPage: ContentTopPage = {
  imgUrl: "",
  title: "",
  content: "",
};

const defaultValuesEnergyNumber: EnergyNumber = {
  number: "",
  advantage: "",
  weakness: "",
};

const defaultValuesClassRoomLinks: ClassRoomLinks[] = [
  {
    imgUrl: "",
    title: "",
  },
];

export default function PopupEdit(props: {
  type: "CONTENT_NUMBER" | "CONTENT_TOP_PAGE" | "CLASS_ROOM_LINKS" | "energy";
  typeNumber?: string;
  contentNumber: any;
  isShowEditContentNumber: boolean;
  handleCloseEditContentNumber: () => void;
  handleResetData: () => void;
}) {
  const {
    contentNumber,
    handleCloseEditContentNumber,
    handleResetData,
    type,
    typeNumber,
  } = props;

  const { defaultValues, schema } =
    type === "CONTENT_NUMBER"
      ? {
          defaultValues: defaultValuesContentNumber,
          schema: contentNumberValidate,
        }
      : type === "CONTENT_TOP_PAGE"
      ? {
          defaultValues: defaultValuesContentTopPage,
          schema: contentTopPageValidate,
        }
      : type === "energy"
      ? {
          defaultValues: defaultValuesEnergyNumber,
          schema: energyNumberValidate,
        }
      : {
          defaultValues: defaultValuesClassRoomLinks,
          schema: classRoomLinksValidate,
        };

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(schema as any),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: defaultValues as any,
  });

  useEffect(() => {
    if (contentNumber) reset(contentNumber);
  }, [contentNumber]);

  const handleEditContentNumber = (data: any) => {
    if (data._id) delete data._id;
    const dataObject =
      type === "CONTENT_NUMBER"
        ? {
            [typeNumber as string]: [data],
          }
        : type === "CONTENT_TOP_PAGE"
        ? {
            contentTopPage: data,
          }
        : {
            classRoomLinks: data,
          };
    editContent(dataObject, "64c728422e26925dbd8d4e95")
      .then(() => {
        handleResetData();
        toast.success("Chỉnh sửa thành công");
      })
      .catch((error) => {
        toast.error("Có lỗi xảy ra!");
        // eslint-disable-next-line
        console.log(error);
      });
  };

  const handleEditEnergyNumber = (data: any) => {
    if (data._id) delete data._id;
    editEnergyNumber({ energyNumber: [data] }, "64c9c4107abd3d155d3923c6")
      .then(() => {
        handleResetData();
        toast.success("Chỉnh sửa thành công");
      })
      .catch((error) => {
        toast.error("Có lỗi xảy ra!");
        // eslint-disable-next-line
        console.log(error);
      });
  };

  return (
    <div className="absolute top-0 left-0 right-0 z-50 w-full h-full md:inset-0 bg-[#08080884] flex flex-col">
      <div className="mx-auto bg-white rounded-lg shadow dark:bg-gray-700 mt-8 w-4/5">
        {type === "CONTENT_NUMBER" && (
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-full">
            <div className="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Chỉnh sửa số {contentNumber?.number}
              </h3>
              <button
                type="button"
                disabled={isSubmitting}
                onClick={handleCloseEditContentNumber}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="small-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form
              className="px-4 mt-3"
              onSubmit={handleSubmit(handleEditContentNumber)}
            >
              <div className="p-3 flex flex-col">
                <div className="flex mb-6">
                  <TextAreaRegister
                    register={register}
                    errMsg={errors?.mota?.message}
                    isAutoFill={false}
                    label={"Mô Tả"}
                    name={"mota"}
                    className="w-1/2 px-2"
                  />
                  <TextAreaRegister
                    register={register}
                    errMsg={errors?.canbang?.message}
                    isAutoFill={false}
                    label={"Cân Bằng"}
                    name={"canbang"}
                    className="w-1/2 px-2"
                  />
                </div>
                <div className="flex">
                  <TextAreaRegister
                    register={register}
                    errMsg={errors?.bagoc?.message}
                    isAutoFill={false}
                    label={"Ba Gốc"}
                    name={"bagoc"}
                    className="w-1/2 px-2"
                  />
                  <TextAreaRegister
                    register={register}
                    errMsg={errors?.badoc?.message}
                    isAutoFill={false}
                    label={"Ba Độc"}
                    name={"badoc"}
                    className="w-1/2 px-2"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center p-6 space-x-8 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="small-modal"
                  type="submit"
                  disabled={isSubmitting}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Chỉnh sửa
                </button>
                <button
                  data-modal-hide="small-modal"
                  type="button"
                  onClick={handleCloseEditContentNumber}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Huỷ
                </button>
              </div>
            </form>
          </div>
        )}
        {type === "CONTENT_TOP_PAGE" && (
          <div className="w-full">
            <div className="flex justify-end pt-2 pr-2">
              <button
                type="button"
                disabled={isSubmitting}
                onClick={handleCloseEditContentNumber}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="small-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="flex items-start justify-center pb-4 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Chỉnh Sửa Nội Dung Trang Chủ
              </h3>
            </div>

            <form
              className="px-2 mt-3"
              onSubmit={handleSubmit(handleEditContentNumber)}
            >
              <div className="p-6 space-y-6">
                <InputRegister
                  register={register}
                  errMsg={errors?.title?.message}
                  isAutoFill={true}
                  name={"title"}
                  label={"Tiêu đề"}
                />
                <TextAreaRegister
                  register={register}
                  errMsg={errors?.content?.message}
                  isAutoFill={true}
                  name={"content"}
                  label={"Nội dung"}
                />
                <InputRegister
                  register={register}
                  errMsg={errors?.imgUrl?.message}
                  isAutoFill={true}
                  label={"Ảnh"}
                  name={"imgUrl"}
                />
              </div>
              <div className="flex items-center justify-center p-6 space-x-8 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="small-modal"
                  type="submit"
                  disabled={isSubmitting}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Chỉnh sửa
                </button>
                <button
                  data-modal-hide="small-modal"
                  type="button"
                  onClick={handleCloseEditContentNumber}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Huỷ
                </button>
              </div>
            </form>
          </div>
        )}
        {type === "energy" && (
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-700 w-full">
            <div className="flex items-center justify-between p-5 border-b rounded-t dark:border-gray-600">
              <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                Chỉnh sửa số {contentNumber?.number}
              </h3>
              <button
                type="button"
                disabled={isSubmitting}
                onClick={handleCloseEditContentNumber}
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="small-modal"
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>

            <form
              className="px-4 mt-3"
              onSubmit={handleSubmit(handleEditEnergyNumber)}
            >
              <div className="p-3 flex flex-col">
                <TextAreaRegister
                  register={register}
                  errMsg={errors?.advantage?.message}
                  isAutoFill={false}
                  label={"Ưu điểm"}
                  name={"advantage"}
                  className="px-2 mb-4"
                />
                <TextAreaRegister
                  register={register}
                  errMsg={errors?.weakness?.message}
                  isAutoFill={false}
                  label={"Nhược điểm"}
                  name={"weakness"}
                  className="px-2"
                />
              </div>
              <div className="flex items-center justify-center p-6 space-x-8 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="small-modal"
                  type="submit"
                  disabled={isSubmitting}
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Chỉnh sửa
                </button>
                <button
                  data-modal-hide="small-modal"
                  type="button"
                  onClick={handleCloseEditContentNumber}
                  className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                >
                  Huỷ
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

PopupEdit.defaultProps = {
  typeNumber: "",
};

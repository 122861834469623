import * as yup from "yup";

const PHONE_NUMBER_REGEX =
  /^(8403|8405|8407|8409|8408)\d{8}$|^(843|845|847|840|848)\d{8}$|^0[35789]\d{8}$|^02\d{9}$/;
const LENGTH_REGEX = /^.{8,32}$/;
// Hidden for simplicity
export const createUserSchema = yup.object({
  username: yup.string().required("Trường này là bắt buộc"),
  password: yup
    .string()
    .min(8, "Mật khẩu phải ít nhất 8 kí tự")
    .max(32, "Mật khẩu không được quá 32 kí tự")
    .required("Trường này là bắt buộc"),
  fullName: yup.string(),
  phoneNumber: yup
    .string()
    .matches(PHONE_NUMBER_REGEX, "Hãy nhập đúng định dạng số điện thoại"),
  groupLink: yup.string().required("Trường này là bắt buộc"),
  teammate: yup.string(),
  email: yup.string(),
  avatar: yup.string(),
  code: yup.string().required("Trường này là bắt buộc"),
});

export const updateUserSchema = yup.object({
  username: yup.string().required("Trường này là bắt buộc"),
  password: yup.string().matches(LENGTH_REGEX, {
    excludeEmptyString: true,
    message: "Mật khẩu phải từ 8 đến 32 kí tự",
  }),
  fullName: yup.string().optional(),
  phoneNumber: yup.string().matches(PHONE_NUMBER_REGEX, {
    excludeEmptyString: true,
    message: "Hãy nhập đúng định dạng số điện thoại",
  }),
  groupLink: yup.string().required("Trường này là bắt buộc"),
  teammate: yup.string().optional(),
  email: yup.string().optional(),
  avatar: yup.string().optional(),
  code: yup.string().required("Trường này là bắt buộc"),
});

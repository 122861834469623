import apiClient from "./api";

export const getUserData = async () => {
  const response: any = await apiClient.get(`/userData/user-data`, {});
  return response;
};

export const removeUserData = async (_id: any) => {
  const response: any = await apiClient.post(`/userData/user-data/delete`, _id);
  return response;
};

export const removeManyUserData = async (ids: string[]) => {
  const response: any = await apiClient.post(`/userData/user-data/deleteMany`, {
    ids,
  });
  return response;
};

import * as yup from "yup";

export const contentNumberValidate = yup.object().shape({
  number: yup.string().required("Trường này là bắt buộc"),
  badoc: yup.string().required("Trường này là bắt buộc"),
  bagoc: yup.string().required("Trường này là bắt buộc"),
  mota: yup.string().optional(),
  canbang: yup.string().optional(),
});

export const energyNumberValidate = yup.object().shape({
  number: yup.string().required("Trường này là bắt buộc"),
  advantage: yup.string().required("Trường này là bắt buộc"),
  weakness: yup.string().required("Trường này là bắt buộc"),
});

export const contentTopPageValidate = yup.object().shape({
  imgUrl: yup.string().required("Trường này là bắt buộc"),
  title: yup.string().required("Trường này là bắt buộc"),
  content: yup.string().required("Trường này là bắt buộc"),
});

export const classRoomLinksValidate = yup.array().of(
  yup
    .object()
    .shape({
      imgUrl: yup.string().required("Trường này là bắt buộc"),
      title: yup.string().required("Trường này là bắt buộc"),
    })
    .optional()
);

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { createUserSchema, updateUserSchema } from "./config/validation";
import InputRegister from "../InputRegister";
import { useEffect } from "react";
import { createUser, updateUser } from "app/services/user";
import { toast } from "react-toastify";

export default function CreateUpdateModal(props: {
  userUpdate: any;
  onClose: () => void;
  isUpdate: boolean;
}) {
  const { userUpdate, onClose, isUpdate } = props;
  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(isUpdate ? updateUserSchema : createUserSchema),
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      username: "",
      password: "",
      fullName: "",
      phoneNumber: "",
      groupLink: "",
      teammate: "",
      email: "",
      avatar: "",
      code: "",
    },
  });

  useEffect(() => {
    if (isUpdate && userUpdate) {
      const data = userUpdate;
      delete data.password;
      reset(data);
    }
    // eslint-disable-next-line
  }, [userUpdate, isUpdate]);
  const isUpdateUserName = () => !!(isUpdate && userUpdate);
  const handleCreateUpdateUser = (data: any) => {
    if (isUpdateUserName()) {
      if (!data.password) delete data.password;
      updateUser(data, data._id)
        .then(() => {
          onClose();
          toast.success("Chỉnh sửa thành công");
        })
        .catch((error) => {
          if (
            error?.response?.data?.code === 400 &&
            error?.response?.data?.errors
          ) {
            Object.entries(error?.response?.data?.errors).forEach(
              ([field, errorMessage]: any) => {
                setError(field, {
                  type: "custom",
                  message: errorMessage,
                });
              }
            );
          } else toast.error("Có lỗi xảy ra!");
          // eslint-disable-next-line
          console.log(error);
        });
    } else {
      createUser(data)
        .then(() => {
          onClose();
          toast.success("Đăng kí thành công");
        })
        .catch((error) => {
          if (
            error?.response?.data?.code === 400 &&
            error?.response?.data?.errors
          ) {
            Object.entries(error?.response?.data?.errors).forEach(
              ([field, errorMessage]: any) => {
                setError(field, {
                  type: "custom",
                  message: errorMessage,
                });
              }
            );
          } else toast.error("Có lỗi xảy ra!");
          // eslint-disable-next-line
          console.log(error);
        });
    }
  };

  return (
    <div className="absolute top-0 left-0 right-0 z-50 w-full h-full md:inset-0 bg-[#08080884] flex flex-col">
      <div className="relative mx-auto bg-white rounded-lg shadow dark:bg-gray-700 mt-8 pb-8">
        <div className="flex justify-end pt-2 pr-2">
          <button
            type="button"
            onClick={onClose}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="defaultModal"
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
        </div>
        <div className="flex items-start justify-center pb-4 border-b rounded-t dark:border-gray-600">
          <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
            {isUpdateUserName()
              ? "SỬA TÀI KHOẢN ĐỒNG ĐỘI"
              : "TẠO TÀI KHOẢN ĐỒNG ĐỘI"}
          </h3>
        </div>

        <form
          className="px-12 mt-3"
          onSubmit={handleSubmit(handleCreateUpdateUser)}
        >
          <div className="mb-6">
            <InputRegister
              register={register}
              errMsg={errors?.fullName?.message}
              isAutoFill={false}
              label="Họ Và Tên"
              name="fullName"
            />
          </div>
          <div className="grid gap-6 mb-6 md:grid-cols-2">
            <InputRegister
              register={register}
              errMsg={errors?.phoneNumber?.message}
              isAutoFill={false}
              label="Số Điện Thoại"
              name="phoneNumber"
            />
            <InputRegister
              register={register}
              errMsg={errors?.teammate?.message}
              isAutoFill={false}
              label="Đồng Đội"
              name="teammate"
            />
            <InputRegister
              register={register}
              errMsg={errors?.groupLink?.message}
              isAutoFill={false}
              label="Link Group"
              name="groupLink"
            />
            <InputRegister
              register={register}
              errMsg={errors?.code?.message}
              isAutoFill={false}
              label="Code URL"
              name="code"
            />
          </div>
          <div className="mb-6">
            <InputRegister
              register={register}
              errMsg={errors?.email?.message}
              isAutoFill={false}
              label="Email Address"
              name="email"
            />
          </div>
          <div className="mb-6">
            <InputRegister
              register={register}
              errMsg={errors?.username?.message}
              isAutoFill={false}
              disabled={isUpdateUserName()}
              label="Tên Đăng Nhập"
              name="username"
            />
          </div>
          <div className="mb-6">
            <InputRegister
              register={register}
              errMsg={errors?.password?.message}
              isAutoFill={false}
              label="Mật Khẩu"
              name="password"
              type="password"
            />
          </div>

          <div className="w-full flex justify-center">
            <button
              type="submit"
              disabled={isSubmitting}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {isUpdateUserName() ? "Chỉnh Sửa Tài Khoản" : "Đăng Kí Tài Khoản"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import apiClient from "./api";

export const getUsers = async () => {
  const response: any = await apiClient.get(`user/users-list`);
  return response;
};

export const getUser = async () => {
  const response: any = await apiClient.get(`user/user`);
  return response;
};

export const createUser = async (data: any) => {
  const response: any = await apiClient.post(`user/register`, data);
  return response;
};

export const updateUser = async (data: any, id: string) => {
  const response: any = await apiClient.put(`user/user/${id}`, data);
  return response;
};

export const deleteUser = async (id: string) => {
  const response: any = await apiClient.delete(`user/user/${id}`);
  return response;
};

import { ContentNumber, EnergyNumber } from "types/ContentNumber";

export const BodyTable = (props: {
  contentNumber: ContentNumber | EnergyNumber;
  type?: string;
  setEditContentNumber: any;
}) => {
  const { contentNumber, setEditContentNumber, type } = props;
  const data: any = type
    ? (contentNumber as EnergyNumber)
    : (contentNumber as ContentNumber);
  return (
    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
      <th
        scope="row"
        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
      >
        {data.number}
      </th>
      <td className="px-6 py-4 whitespace-pre-line">
        {!type ? data.mota : data.advantage}
      </td>
      <td className="px-6 py-4 whitespace-pre-line">
        {!type ? data.bagoc : data.weakness}
      </td>
      {!type && (
        <>
          <td className="px-6 py-4 whitespace-pre-line">{data.badoc}</td>
          <td className="px-6 py-4 whitespace-pre-line">{data.canbang}</td>
        </>
      )}

      <td className="px-6 py-4">
        <span
          onClick={() => setEditContentNumber(contentNumber)}
          className="cursor-pointer font-medium text-blue-600 dark:text-blue-500 hover:underline text-start"
        >
          Chỉnh sửa
        </span>
      </td>
    </tr>
  );
};

BodyTable.defaultProps = {
  type: undefined,
};

import { ROUTES } from "app/configs/routers";
import AdminPage from "app/containers/AdminPage";
import LoginPage from "app/containers/LoginPage";

const routes = [
  {
    path: ROUTES.ADMIN,
    element: AdminPage,
    meta: {
      middleware: ["auth"],
    },
  },
  {
    path: ROUTES.LOGIN,
    element: LoginPage,
    meta: {
      middleware: [],
    },
  },
];

export { routes };

import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

export default function UserData(props: {
  handleDeleteData: (id: string) => void;
  handleDownloadData: any;
  refCSV: React.MutableRefObject<any>;
  dataChecked: any;
  headers: {
    label: string;
    key: string;
  }[];
  handleDeleteManyData: any;
  setDataChecked: React.Dispatch<any>;
  userDatas: any;
  isLoading: boolean;
}) {
  const {
    handleDeleteData,
    handleDownloadData,
    refCSV,
    dataChecked,
    headers,
    handleDeleteManyData,
    setDataChecked,
    userDatas,
    isLoading,
  } = props;

  const [code, setCode] = useState<any>(null);
  const [filter, setFilter] = useState<any>(null);

  const [defaultDatas, setDefaultDatas] = useState<any>(null);

  const getUniqueRules = (data: any) => {
    const rules = data.map((item: any) => item.code);
    const uniqueRules = [...new Set(rules)];
    setCode(uniqueRules);
  };

  useEffect(() => {
    if (userDatas) {
      setDefaultDatas(userDatas);
      getUniqueRules(userDatas);
    }
  }, [userDatas]);

  useEffect(() => {
    if (filter) {
      if (filter === "Tất cả") {
        setDefaultDatas(userDatas);
      } else {
        const a = userDatas.filter((val: any) => val.code === filter);
        setDefaultDatas(a);
      }
    }
  }, [filter]);

  return (
    <div className="p-4 sm:ml-64">
      <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14 min-h-[calc(100vh-88px)]">
        <div className="flex w-full justify-end">
          <button
            type="button"
            onClick={handleDownloadData}
            className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
          >
            Tải đã chọn (CSV)
          </button>
          <CSVLink
            ref={refCSV}
            data={dataChecked.map((obj: any, index: number) => {
              return {
                index: index + 1,
                ...obj,
              };
            })}
            headers={headers}
            filename={"userData.csv"}
            className="btn btn-primary hidden"
            target="_blank"
          >
            Download me
          </CSVLink>
          <button
            type="button"
            onClick={handleDeleteManyData}
            className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
          >
            Xóa đã chọn
          </button>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Số thứ tự
                </th>
                <th scope="col" className="px-6 py-3">
                  Họ và tên đệm
                </th>
                <th scope="col" className="px-6 py-3">
                  Tên
                </th>
                <th scope="col" className="px-6 py-3">
                  Giới tính
                </th>
                <th scope="col" className="px-6 py-3">
                  Ngày Sinh
                </th>
                <th scope="col" className="px-6 py-3">
                  Số điện thoại
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Tên phụ
                </th>
                <th scope="col" className="px-6 py-3">
                  <select onChange={(e) => setFilter(e.target.value)}>
                    <option value="Tất cả" key={"Tất cả"}>
                      Tất cả
                    </option>
                    {code &&
                      code.map((val: any) => (
                        <option value={val} key={val}>
                          {val}
                        </option>
                      ))}
                  </select>
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>

                <th scope="col" className="p-4">
                  <div className="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) setDataChecked(userDatas);
                        else setDataChecked([]);
                      }}
                      checked={
                        userDatas.length === dataChecked.length &&
                        (userDatas.length > 0 || dataChecked.length > 0)
                      }
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label htmlFor="checkbox-all-search" className="sr-only">
                      checkbox
                    </label>
                  </div>
                </th>
              </tr>
            </thead>
            {isLoading ? (
              <tbody>
                <tr>
                  <td colSpan={8}>
                    <div role="status" className="w-full animate-pulse mt-5">
                      <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 w-full"></div>
                    </div>
                    <div role="status" className="w-full animate-pulse">
                      <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 w-full"></div>
                    </div>
                    <div role="status" className="w-full animate-pulse">
                      <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 w-full"></div>
                    </div>
                  </td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {defaultDatas &&
                  defaultDatas.length > 0 &&
                  defaultDatas.map((data: any, index: number) => (
                    <tr
                      key={data?._id}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                      >
                        {index + 1}
                      </th>
                      <td className="px-6 py-4">{data?.firstName}</td>
                      <td className="px-6 py-4">{data?.lastName}</td>
                      <td className="px-6 py-4">{data?.gender}</td>
                      <td className="px-6 py-4">{data?.birthday}</td>
                      <td className="px-6 py-4">{data?.tel}</td>
                      <td className="px-6 py-4">{data?.email}</td>
                      <td className="px-6 py-4">{data?.authorName}</td>
                      <td className="px-6 py-4">{data?.code}</td>

                      <td className="flex items-center px-6 py-4 space-x-3">
                        <span
                          className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer"
                          onClick={() => handleDeleteData(data._id)}
                        >
                          Remove
                        </span>
                      </td>
                      <td className="w-4 p-4">
                        <div className="flex items-center">
                          <input
                            id="checkbox-table-search-3"
                            type="checkbox"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setDataChecked((prev: any) => [...prev, data]);
                              } else
                                setDataChecked((prev: any) =>
                                  prev.filter(
                                    (val: any) => val._id !== data._id
                                  )
                                );
                            }}
                            checked={
                              !!dataChecked.find(
                                (val: any) => val._id === data._id
                              )
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          />
                          <label
                            htmlFor="checkbox-table-search-3"
                            className="sr-only"
                          >
                            checkbox
                          </label>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

import { useState } from "react";
import { ContentNumber } from "types/ContentNumber";
import PopupEdit from "./component/PopupEdit";
import { BodyTable } from "./component/BodyTable";

export default function ContentNumberPage(props: {
  contentNumbers: ContentNumber[];
  typeNumber: string;
  resetData: () => void;
}) {
  const { contentNumbers, resetData, typeNumber } = props;
  const [isEditContentNumber, setIsEditContentNumber] =
    useState<boolean>(false);
  const [editContentNumber, setEditContentNumber] =
    useState<ContentNumber | null>(null);

  const handleCloseEditContentNumber = () => {
    setIsEditContentNumber(false);
    setEditContentNumber(null);
  };
  const handleResetData = () => {
    resetData();
    handleCloseEditContentNumber();
  };

  const handleShowEditContentNumber = (data: ContentNumber) => {
    setIsEditContentNumber(true);
    setEditContentNumber(data);
  };
  return (
    <>
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14 min-h-[calc(100vh-88px)]">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-left">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Con Số
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Mô Tả
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ba Gốc
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ba Độc
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Cân Bằng
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Chỉnh sửa
                  </th>
                </tr>
              </thead>
              <tbody className="align-top">
                {contentNumbers.map((value: ContentNumber) => (
                  <BodyTable
                    key={value.number}
                    contentNumber={value}
                    setEditContentNumber={handleShowEditContentNumber}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isEditContentNumber && editContentNumber && (
        <PopupEdit
          type="CONTENT_NUMBER"
          typeNumber={typeNumber}
          contentNumber={editContentNumber}
          isShowEditContentNumber={isEditContentNumber}
          handleCloseEditContentNumber={handleCloseEditContentNumber}
          handleResetData={handleResetData}
        />
      )}
    </>
  );
}

import { EnergyNumber } from "types/ContentNumber";
import { BodyTable } from "./component/BodyTable";
import { useState } from "react";
import PopupEdit from "./component/PopupEdit";

export default function EnergyNumberPage(props: {
  energyNumbers: EnergyNumber[];
  resetData: () => void;
}) {
  const { energyNumbers, resetData } = props;
  const [isEditEnergyNumber, setIsEditEnergyNumber] = useState<boolean>(false);
  const [editEnergyNumber, setEditEnergyNumber] = useState<EnergyNumber | null>(
    null
  );

  const handleCloseEditEnergyNumber = () => {
    setIsEditEnergyNumber(false);
    setEditEnergyNumber(null);
  };
  const handleResetData = () => {
    resetData();
    handleCloseEditEnergyNumber();
  };

  const handleShowEditEnergyNumber = (data: EnergyNumber) => {
    setIsEditEnergyNumber(true);
    setEditEnergyNumber(data);
  };

  return (
    <>
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14 min-h-[calc(100vh-88px)]">
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Con Số
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Ưu Điểm
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Nhược Điểm
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Chỉnh sửa
                  </th>
                </tr>
              </thead>
              <tbody>
                {energyNumbers &&
                  energyNumbers.length > 0 &&
                  energyNumbers.map((value: EnergyNumber) => (
                    <BodyTable
                      key={value.number}
                      type="energy"
                      contentNumber={value}
                      setEditContentNumber={handleShowEditEnergyNumber}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {isEditEnergyNumber && editEnergyNumber && (
        <PopupEdit
          type="energy"
          contentNumber={editEnergyNumber}
          isShowEditContentNumber={isEditEnergyNumber}
          handleCloseEditContentNumber={handleCloseEditEnergyNumber}
          handleResetData={handleResetData}
        />
      )}
    </>
  );
}

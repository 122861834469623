import apiClient from "./api";

export const getContent = async () => {
  const response: any = await apiClient.get(`content/content`);
  return response;
};

export const editContent = async (data: any, id: string) => {
  const response: any = await apiClient.put(`content/content/${id}`, data);
  return response;
};

export const getEnergyNumber = async () => {
  const response: any = await apiClient.get(`energy-number/energy-number`);
  return response;
};

export const editEnergyNumber = async (data: any, id: string) => {
  const response: any = await apiClient.put(
    `energy-number/energy-number/${id}`,
    data
  );
  return response;
};

import CreateUpdateModal from "app/components/CreateUpdateModal";
import DeleteUserModal from "app/components/DeleteUserModal";
import { MAX_USER } from "app/configs";
import { useState } from "react";
import { toast } from "react-toastify";

export default function UserData(props: {
  userDatas: any;
  isLoading: boolean;
  handleGetUsers: any;
}) {
  const { userDatas, isLoading, handleGetUsers } = props;

  const [isCreateUpdate, setIsCreateUpdate] = useState<boolean>(false);
  const [isShowDelete, setIsShowDelete] = useState<boolean>(false);
  const [idUserDelete, setIdUserDelete] = useState<string | null>(null);

  const [userUpdate, setUserUpdate] = useState<any>(null);

  const handleCreateUpdate = (isUpdate: boolean, data?: any) => {
    if (isUpdate) {
      setIsCreateUpdate(true);
      setUserUpdate(data);
    } else {
      if (userUpdate?.length === MAX_USER)
        return toast.error("Đã max số lượng người dùng\nHãy nâng cấp");
      setUserUpdate(null);
      setIsCreateUpdate(true);
    }
  };

  const handleDeleteUser = (id: string) => {
    setIdUserDelete(id);
    setIsShowDelete(true);
  };
  const handleCloseModal = () => {
    setIsCreateUpdate(false);
    setUserUpdate(null);
    handleGetUsers();
  };

  const handleCloseDelete = () => {
    setIsShowDelete(false);
    setIdUserDelete(null);
    handleGetUsers();
  };

  return (
    <div className="relative w-full h-full">
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14 min-h-[calc(100vh)]">
          <div className="flex w-full justify-end">
            <button
              type="button"
              onClick={() => handleCreateUpdate(false)}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Thêm Đồng Đội
            </button>
          </div>
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-b">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Số thứ tự
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tên Đăng Nhập
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Tên
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Số điện thoại
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Link Group
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Đồng đội
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Code URL
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3 text-center">
                    Action
                  </th>
                </tr>
              </thead>
              {isLoading ? (
                <tbody>
                  <tr>
                    <td colSpan={8}>
                      <div role="status" className="w-full animate-pulse mt-5">
                        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 w-full"></div>
                      </div>
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 w-full"></div>
                      </div>
                      <div role="status" className="w-full animate-pulse">
                        <div className="h-4 bg-gray-200 rounded-full dark:bg-gray-700 mb-4 w-full"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ) : (
                <tbody>
                  {userDatas &&
                    userDatas.length > 0 &&
                    userDatas.map((data: any, index: number) => (
                      <tr
                        key={data?._id}
                        className="bg-white text-center border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <th
                          scope="row"
                          className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          {index + 1}
                        </th>
                        <td className="px-6 py-4">{data?.username}</td>
                        <td className="px-6 py-4">{data?.fullName}</td>
                        <td className="px-6 py-4">{data?.phoneNumber}</td>
                        <td className="px-6 py-4">
                          <a
                            href={data?.groupLink}
                            target="t_blank"
                            className="text-blue-400"
                          >
                            {data?.groupLink}
                          </a>
                        </td>
                        <td className="px-6 py-4">{data?.teammate}</td>
                        <td className="px-6 py-4">{data?.code}</td>
                        <td className="px-6 py-4">{data?.email}</td>

                        <td className="flex justify-between items-center px-6 py-4 whitespace-nowrap">
                          <span
                            className="font-medium text-blue-600 dark:text-blue-400 hover:underline cursor-pointer mr-4"
                            onClick={() => handleCreateUpdate(true, data)}
                          >
                            Chỉnh Sửa
                          </span>
                          <span
                            className="font-medium text-red-600 dark:text-red-400 hover:underline cursor-pointer"
                            onClick={() => handleDeleteUser(data._id)}
                          >
                            Xoá
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {isCreateUpdate && (
        <CreateUpdateModal
          userUpdate={userUpdate}
          onClose={handleCloseModal}
          isUpdate={isCreateUpdate}
        />
      )}
      {isShowDelete && idUserDelete && (
        <DeleteUserModal
          userId={idUserDelete}
          handleCloseDelete={handleCloseDelete}
          isShowDelete={isShowDelete}
        />
      )}
    </div>
  );
}
